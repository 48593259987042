import {
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from "mobile-device-detect";
import { isLoggedIn } from "utils/authentication";
import { mError, mLog } from "utils/logger";
import { getPersonType } from "./ABTestingHelper";
import { MixpanelAnalytics } from "./Analytics";

const getDefaultProps = (): any => {
  const authenticated = isLoggedIn();
  const landingType = getPersonType("landingType");
  const signupType = getPersonType("signupType");
  const dashboardType = getPersonType("SimilarProfileType");

  const returnObject = {
    State: authenticated ? "Logged In" : "Logged Out",
    os_name: osName,
    os_version: osVersion,
    mobile_model: mobileModel || "NA",
    mobile_vendor: mobileVendor || "NA",
    landing_type: landingType || "NA",
    signup_type: signupType || "NA",
    dashboard_type: dashboardType || "NA",
  };
  returnObject["$os"] = osName || "NA";
  returnObject["$screen_height"] = window?.innerHeight || "NA";
  returnObject["$screen_width"] = window?.innerWidth || "NA";
  return returnObject;
};

const actions = {
  identify: (id: string, traits = {}): void => {
    try {
      mLog("Analytics Identify: ", id, traits);
      MixpanelAnalytics.identify(id, traits);
    } catch (err) {
      mError("Analytics identify not captured", err);
    }
  },
  alias: (id: string): void => {
    try {
      mLog("Analytics Alias: ", id);
      MixpanelAnalytics.alias(id);
    } catch (err) {
      mError("Analytics alias not captured", err);
    }
  },
  track: (name: string, props = {}): void => {
    try {
      const mergedProps = { ...getDefaultProps(), ...props };
      MixpanelAnalytics.track(name, mergedProps);
      mLog("Analytics Event: ", name, props);
    } catch (err) {
      mError("Analytics track not captured", err);
    }
  },
  page: (name: string, props = {}): void => {
    try {
      const mergedProps = { ...getDefaultProps(), ...props };
      MixpanelAnalytics.page(name, mergedProps);
      mLog("Analytics Page Event: ", name, mergedProps);
    } catch (err) {
      mError("Analytics track not captured", err);
    }
  },
  reset: (): void => {
    try {
      MixpanelAnalytics.reset();
      mLog("Analytics Reset Event!");
    } catch (err) {
      mError("Analytics track not captured", err);
    }
  },
  register: (props = {}): void => {
    try {
      mLog("Analytics Register Event: ", props);
      MixpanelAnalytics.register(props);
    } catch (err) {
      mError("Analytics track not captured", err);
    }
  },
  register_once: (props = {}): void => {
    try {
      mLog("Analytics Register Once Event: ", props);
      MixpanelAnalytics.register_once(props);
    } catch (err) {
      mError("Analytics track not captured", err);
    }
  },
  people: (props = {}): void => {
    try {
      mLog("Analytics People Event: ", props);
      MixpanelAnalytics.people(props);
    } catch (err) {
      mError("Analytics people not captured", err);
    }
  },
  people_once: (props = {}): void => {
    try {
      mLog("Analytics People Event: ", props);
      MixpanelAnalytics.people(props);
    } catch (err) {
      mError("Analytics people not captured", err);
    }
  },
};

export const Analytics = actions;
