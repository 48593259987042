// In case of changes in the environment variables (addition/deletion), changes have to be made here
//  as well for client side accessiblity
const config = {
  BASE_URL: process.env.NEXT_PUBLIC_URL as string,
  API_URL: process.env.NEXT_PUBLIC_BASE_URL as string,
  COOKIE_DOMAIN: process.env.COOKIE_DOMAIN as string,
  SEGMENT_ID: process.env.NEXT_PUBLIC_SEGMENT_ID as string,
  IS_PROD: process.env.NEXT_PUBLIC_IS_PROD as string,
  OPTIMIZE_ID: process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_ID as string,
  BEAMER_ID: process.env.NEXT_PUBLIC_BEAMER_ID as string,
  LINKEDIN_CLIENT_ID: process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID as string,
  ZIPPY_KEY: process.env.NEXT_PUBLIC_ZIPPY_KEY as string,
  FB_PIXEL_ID: process.env.NEXT_PUBLIC_FB_PIXEL_ID as string,
  GA_ID: process.env.NEXT_PUBLIC_GA_ID as string,
  GTM_ID: process.env.NEXT_PUBLIC_GTM_ID as string,
  MAINTENANCE_MODE: process.env.NEXT_PUBLIC_MAINTENANCE_MODE as string,
  RAZORPAY_KEY: process.env.NEXT_PUBLIC_RAZORPAY_KEY as string,
  CAROUSEL_API_URL: process.env.NEXT_PUBLIC_CAROUSEL_API_URL as string,
  RECAPTCHA_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_KEY as string,
  BEAMER_API_URL: "https://api.getbeamer.com/v0/" as string,
  TWITTER_CLIENT_ID: process.env.NEXT_PUBLIC_TWITTER_CLIENT_ID as string,
  BEAMER_NPS_KEY: process.env.NEXT_PUBLIC_BEAMER_KEY as string,
  API_TOKEN: process.env.NEXT_PUBLIC_API_TOKEN as string,
  CLARITY_TOKEN: process.env.NEXT_PUBLIC_CLARITY_TOKEN as string,
  MIXPANEL_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN as string,
  INBOX_WORKSPACEKEY: process.env.NEXT_PUBLIC_INBOX_WORKSPACEKEY as string,
  INBOX_WORKSPACESECRET: process.env
    .NEXT_PUBLIC_INBOX_WORKSPACESECRET as string,
  AI_IMAGE_BASE_URL: "https://sdai.topmate.io/" as string,
  JSONLINK_APIKEY: process.env.NEXT_PUBLIC_JSONLINK_APIKEY as string,
  PAYPAL_CLIENT_ID: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID as string,
};

export default config;
