import "@styles/rootVars.scss";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { ConfigProvider } from "antd";
import "antd/dist/reset.css";
import { ANT_CUSTOM_THEME } from "configs/AntThemeConfig";
import { AnimatePresence, MotionConfig, motion } from "framer-motion";
import mixpanel from "mixpanel-browser";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { Router, useRouter } from "next/router";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { Routes } from "services/constants/RouteConstants";
import "styles/global.scss";
import { insertSpaces } from "utils/Analytics/Analytics";
import { Analytics } from "utils/Analytics/AnalyticsHelper";
import config from "utils/config";
import { setCookie } from "utils/cookie-manager";
import zipy from "zipyai";

const ZIPY_KEY = config.ZIPPY_KEY ?? "";

const MaintenanceMode = dynamic(
  () => import("components/Common/MaintenanceMode"),
  {
    ssr: false,
  }
);
const tagManagerArgs = {
  gtmId: config?.GTM_ID,
};

// Create a client for react query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 2 * (60 * 1000), // 2 mins
      cacheTime: 10 * (60 * 1000), // 10 mins
    },
  },
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const { query } = router;
  const isMaintenanceMode = config.MAINTENANCE_MODE === "true" ? true : false;
  const GOOGLE_ANALYTICS_ID = config.GA_ID ?? "";
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);

  const webURL = config.BASE_URL?.substring(0, config.BASE_URL?.length - 1);
  // const canonicalUrl = (
  //   webURL + (router.asPath === "/" ? "" : router.asPath)
  // ).split("?")[0];

  const canonicalUrl = "https://bunchpot.xyz";

  const storeUTMParameters = (): void => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmCookies = [
      "utm_source",
      "utm_page",
      "utm_medium",
      "utm_campaign",
      "http_referer",
    ];
    if (query.http_referrer) {
      setCookie("http_referrer", urlParams.get("http_referrer") || "");
    }
    utmCookies.forEach((name) => {
      if (urlParams.get(name)) {
        setCookie(name, urlParams.get(name) || "");
      }
    });
  };

  const logPageLoadEvents = (): void => {
    const match = Routes.find((route) => route.pattern === location?.pathname);
    const currentPage: string = match?.name ? insertSpaces(match?.name) : "";
    ReactGA.set({ page: location?.pathname }); // Update the user's current page
    // ReactGA.pageview(window.location.pathname);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: currentPage,
    });
    Analytics.page(currentPage);
  };

  useEffect(() => {
    // zipy initialization
    zipy.init(ZIPY_KEY);

    TagManager.initialize(tagManagerArgs);
    // Note: This is configured to track an event for every page view automatically. We also recommend
    // using localStorage instead of the default cookie for persistence.
    mixpanel.init(config.MIXPANEL_TOKEN, {
      debug: config.IS_PROD === "true" ? false : true,
      persistence: "localStorage",
    });

    setTimeout(() => {
      storeUTMParameters();
      logPageLoadEvents();
    });

    Router.events.on("routeChangeComplete", () => {
      storeUTMParameters();
      logPageLoadEvents();
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <ConfigProvider theme={ANT_CUSTOM_THEME}>
          <Head>
            <link rel="canonical" href={canonicalUrl} />
            <meta property="og:url" content={canonicalUrl} />
          </Head>
          {isMaintenanceMode ? (
            <MaintenanceMode />
          ) : (
            <AnimatePresence mode="wait">
              <MotionConfig transition={{ duration: 1 }}>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Component {...pageProps} key={router?.asPath} />
                  <SpeedInsights sampleRate={0.05} />
                </motion.div>
              </MotionConfig>
            </AnimatePresence>
          )}
        </ConfigProvider>
        {config.IS_PROD === "false" && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </Hydrate>
    </QueryClientProvider>
  );
};

export default MyApp;
