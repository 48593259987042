import mixpanel from "mixpanel-browser";
import { getPersonType } from "utils/Analytics/ABTestingHelper";
import { Analytics } from "utils/Analytics/AnalyticsHelper";
import zipy from "zipyai";

type Traits = {
  name: string;
};

export const getTraitsObj = (user: any): any => {
  const {
    id,
    first_name,
    last_name,
    username,
    title,
    description,
    phone,
    profile_pic,
    email,
    name,
    date_joined: dateJoined,
    is_email_verified: isEmailVerified,
    profile_completion: profileCompletion,
    is_featured: isFeatured,
    service_added: serviceAdded,
    slots_added: slotsAdded,
  } = user;
  const traitsObj = {
    id: id || "NA",
    expert_id: id || "NA",
    firstName: first_name || "NA",
    lastName: last_name || "NA",
    username: username || "NA",
    name: name ? name : `${first_name} ${last_name}` || "NA",
    title: title || "NA",
    description: description || "NA",
    email: email || "NA",
    phone: phone || "NA",
    avatar: profile_pic || "NA",
    createdAt: dateJoined || "NA",
    isEmailVerified: isEmailVerified,
    profileCompletion: profileCompletion || "NA",
    isFeatured: isFeatured,
    serviceAdded: serviceAdded,
    slotsAdded: slotsAdded,
    landingType: getPersonType("landingType") || "NA",
    signupType: getPersonType("signupType") || "NA",
    dashboardType: getPersonType("SimilarProfileType") || "NA",
  };
  return traitsObj;
};

export const insertSpaces = (text: string): string => {
  let inputText = text;
  inputText = inputText.replace(/([a-z])([A-Z])/g, "$1 $2");
  inputText = inputText.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
  return inputText;
};

const Track = (name: string, props = {}): void => {
  mixpanel.track(name, props);
};

const Page = (name: string, props = {}): void => {
  mixpanel.track_pageview({ page: name, ...props });
};

const Identify = (id: string, traits: Traits): void => {
  mixpanel.identify(id);
  People(traits);
};

const Register = (traits: Traits): void => {
  mixpanel.register(traits);
};

const People = (traits: Traits): void => {
  mixpanel.people.set(traits);
};

const PeopleOnce = (traits: Traits): void => {
  mixpanel.people.set_once(traits);
};

const RegisterOnce = (traits: Traits): void => {
  mixpanel.register_once(traits);
};

const Alias = (name: string): void => {
  mixpanel.alias(name);
};

const Reset = (): void => {
  mixpanel.reset();
};

export const identifyUser = (id, traits): void => {
  zipy.identify(id.toString(), traits);
  Analytics.identify(id, traits);
};

export const MixpanelAnalytics = {
  track: (name: string, props = {}): void => Track(name, props),
  identify: (id: string, traits: any): void => Identify(id, traits),
  alias: (name: string): void => Alias(name),
  page: (name: string, props = {}): void => Page(name, props),
  reset: (): void => Reset(),
  register: (traits: any): void => Register(traits),
  register_once: (traits: any): void => RegisterOnce(traits),
  people: (traits: any): void => People(traits),
  people_once: (traits: any): void => PeopleOnce(traits),
};
